import React from "react"
import { Flex } from "@chakra-ui/core"
import { navigate } from "gatsby"
import { StringParam, useQueryParam } from "use-query-params"
import AuthContainer from "../../components/Account/AuthContainer"
import PageContainer from "../../components/PageContainer"

export default function Register() {
  const [returnUrl] = useQueryParam("return_url", StringParam)

  const handleAuthSuccess = () => {
    navigate(returnUrl || "/account/addresses")
  }

  return (
    <PageContainer>
      <Flex align="center" justify="center">
        <AuthContainer
          width="23.75rem"
          initialForm="signup"
          onLoginSuccess={handleAuthSuccess}
          onSignUpSuccess={handleAuthSuccess}
        />
      </Flex>
    </PageContainer>
  )
}
